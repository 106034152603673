import queryString from 'query-string'
// eslint-disable-next-line
export default {
    parseParams: function parseParams(paramsStr) {
        return function (params) {
            return Object.keys(params).length === 0 ? null : Object.keys(params) // Parses all strings that are really Doubles
                .map(function (key) {
                    return {[key]: /^[\d+.]+$/.test(params[key]) ? parseFloat(params[key]) : params[key] }
                }) // Converts array of objects to object
                .reduce(function (a, b) {
                    return Object.assign({}, a, b);
                });
        }(queryString.parse(paramsStr));
    }
}