export const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(function(word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

export const getCookie = (name) => {
    if(typeof document.cookie.split('; ').find(row => row.startsWith(name + '=')) !== 'undefined') {
        return document.cookie.split('; ').find(row => row.startsWith(name + '=')).split('=')[1];
    }
    return null
}

export const getParam = param => {
    param = param.toLowerCase();
    // eslint-disable-next-line no-useless-escape
    param = param.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]')
    let regexS = '[\\?&]' + param + '=([^&#]*)'
    let regex = new RegExp(regexS)
    let results = regex.exec(window.location.search.toLowerCase())
    if (results == null)
        return null
    else
        return decodeURIComponent(results[1].replace(/\+/g, ` `))
}