import React, { Component, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
    useLocation, 
    useHistory 
} from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import Loadable from 'react-loadable';
import PageLoader from './components/PageLoader.js'
import Loader from './components/Loader.js'
import withTracker from './components/withTracker.js';
import { titleCase, getCookie, getParam } from './utility/helpers';
import './App.css';

const LoadFromIndex = () => {
    return (
        <div className="loading-page">
            <Loader />
        </div>
    )
}

const AsyncIndexPage = Loadable({loader: () => import( /* webpackPreload: true */ './pages/IndexPage.js'),loading: LoadFromIndex})
const AsyncBargainSalePage= Loadable({loader: () => import( /* webpackPreload: true */ './pages/BargainSale/BargainSalePage.js'),loading: LoadFromIndex})
const AsyncGiftAnnuityPage = Loadable({loader: () => import( /* webpackPreload: true */ './pages/GiftAnnuity/GiftAnnuityPage.js'),loading: LoadFromIndex})
const AsyncLeadTrustPage = Loadable({loader: () => import( /* webpackPreload: true */ './pages/LeadTrust/LeadTrustPage.js'),loading: LoadFromIndex})
const AsyncOutrightGiftPage = Loadable({loader: () => import( /* webpackPreload: true */ './pages/OutrightGift/OutrightGiftPage.js'),loading: LoadFromIndex})
const AsyncRemainderAnnuityTrustPage = Loadable({loader: () => import(/* webpackPreload: true */ './pages/RemainderAnnuityTrust/RemainderAnnuityTrustPage.js'),loading: LoadFromIndex})
const AsyncRetainedLifeEstatePage = Loadable({loader: () => import( /* webpackPreload: true */ './pages/RetainedLifeEstate/RetainedLifeEstatePage.js'),loading: LoadFromIndex})
const Async404Page = Loadable({loader: () => import('./pages/404.js'),loading: LoadFromIndex})

const pages = require('./pages.json')
const getPageData = path => pages.filter(props => props.slug === path)[0]

export const CLATContext = React.createContext(
    getPageData('lead-trust')
)

export const CRTContext = React.createContext(
    getPageData('charitable-remainder-trust')
)

const Pages = () => (

    <Switch>
        <Route exact path="/:clientName" component={withTracker(AsyncIndexPage)} />

        <Route exact path="/:clientName/gift-annuity" component={PageLoader(AsyncGiftAnnuityPage,getPageData('gift-annuity'))} />

        <Route exact path="/:clientName/lead-trust" component={PageLoader(AsyncLeadTrustPage,getPageData('lead-trust'))} />

        <Route exact path="/:clientName/outright-gift" component={PageLoader(AsyncOutrightGiftPage,getPageData('outright-gift'))} />

        <Route exact path="/:clientName/retained-life-estate" component={PageLoader(AsyncRetainedLifeEstatePage,getPageData('retained-life-estate'))} />

        <Route exact path="/:clientName/bargain-sale" component={PageLoader(AsyncBargainSalePage,getPageData('bargain-sale'))} />

        <Route exact path="/:clientName/charitable-remainder-trust" component={PageLoader(AsyncRemainderAnnuityTrustPage,getPageData('charitable-remainder-trust'))} />

        <Route exact path="/" render={ () => <Redirect to="/default" />} />

        <Route path="*" component={Async404Page} />
    </Switch>
)

const WrappedRoutes = () => {
    const location = useLocation();
    const history = useHistory();
    //let [state, setState] = useState(null);

    useEffect(() => {
        var path = typeof location.pathname.split('/')[2] !== 'undefined' ? location.pathname.split('/')[2] : 'Home';
            path = path.replace(/\//g,' ').split('?')[0];
        var page = titleCase(path);

        var orgId = location.pathname.split('/')[1] !== 'default' ? location.pathname.split('/')[1].toUpperCase() : 'A0000000';

        var siteSection2 = 'Home';

        if (path === 'gift-annuity' || path === 'charitable-remainder-trust') siteSection2 = 'Gifts That Pay You Income'
        if (path === 'outright-gift' || path === 'lead-trust') siteSection2 = 'Gifts You Make Today'
        if (path === 'bargain-sale' || path === 'retained-life-estate') siteSection2 = 'Gifts of Assets'

        var subId = getCookie('Stl_SubId');
        var intExtValue = getCookie('Stl_Visit_Stelter') !== null ? 'internal_stelter' : getCookie('Stl_Visit_Client') !== null ? 'internal_client' : 'external';

        let bgColor = '#e4e0e1';
        let showHomeBtn = 'true';
        if(getParam('bg') !== null && getParam('bg').match(/^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/g)) {
            bgColor = '#' + getParam('bg');
        }

        if(getParam('home') !== null && (getParam('home').toString() === 'false' || getParam('home').toString() === '0')) {
            showHomeBtn = 'false'
        }

        try {
            sessionStorage.setItem('bgColor',bgColor)
            sessionStorage.setItem('homeBtn',showHomeBtn)
        } catch (e) {
            // console.log('sessionStorage is disabled')
        }
        

        if(getParam('subid') !== null) {
            subId = getParam('subid');
            var secureCookie = window.location.protocol === 'https:' ? 'secure;': '';
            var exp = (new Date(new Date().setFullYear(new Date().getFullYear() + 1))).toUTCString();
            document.cookie = 'Stl_SubId='+subId+'; samesite=strict;'+secureCookie+' expires='+exp+';';
        }

        const giUseAnalytics = () => {
            var noAnalytics = false;
            var noAnalyticsQuery = getParam('stl_analytics') !== null && getParam('stl_analytics').toString() === 'false';
            if(noAnalyticsQuery) {
                noAnalytics = true;
                document.cookie = "Stl_NoAnalytics="+orgId+"; path=/; secure;";
            } else {
                if (getCookie('Stl_NoAnalytics') !== null && getCookie('Stl_NoAnalytics') === orgId) {
                    noAnalytics = true;
                }
            }

            return !noAnalytics;
        };
        
        if(giUseAnalytics()) {
            if (history.action === 'PUSH') {
                if (subId === null) {
                    window.stlDigitalData = {
                        internalExternal: intExtValue,
                        page: {
                            pageInfo: {
                                pageName: orgId + ":" + page,
                                pageType: "Gift Illustrator"
                            },
                            prefix: {
                                sitePrefix: orgId
                            },
                            section: {
                                section1: "Gift Illustrator",
                                section2: siteSection2
                            }
                        }
                    }
                } else {
                    window.stlDigitalData = {
                        internalExternal: intExtValue,
                        page: {
                            pageInfo: {
                                pageName: orgId + ":" + page,
                                pageType: "Gift Illustrator"
                            },
                            prefix: {
                                sitePrefix: orgId
                            },
                            section: {
                                section1: "Gift Illustrator",
                                section2: siteSection2
                            }
                        },
                        user: {
                            profile: {
                                subId: subId
                            }
                        }
                    }
                }
    
                if(typeof window._satellite !== 'undefined') window._satellite.track("pageview", window.stlDigitalData);
            
            } else {
                if (typeof window.stlDigitalData === 'undefined') {
                    if (subId === null) {
                        window.stlDigitalData = {
                            internalExternal: intExtValue,
                            page: {
                                pageInfo: {
                                    pageName: orgId + ":" + page,
                                    pageType: "Gift Illustrator"
                                },
                                prefix: {
                                    sitePrefix: orgId
                                },
                                section: {
                                    section1: "Gift Illustrator",
                                    section2: siteSection2
                                }
                            }
                        }
                    } else {
                        window.stlDigitalData = {
                            internalExternal: intExtValue,
                            page: {
                                pageInfo: {
                                    pageName: orgId + ":" + page,
                                    pageType: "Gift Illustrator"
                                },
                                prefix: {
                                    sitePrefix: orgId
                                },
                                section: {
                                    section1: "Gift Illustrator",
                                    section2: siteSection2
                                }
                            },
                            user: {
                                profile: {
                                    subId: subId
                                }
                            }
                        }
                    }
                }
                
                if (document.getElementById("launch-script") === null && giUseAnalytics()) {
                    var h = window.location.origin,
                        n = (h.indexOf("calculator.giftillustrator.com") > -1 && h.indexOf("staging") === -1 && h.indexOf("pdf.stelter.com") === -1 && h.indexOf("api.stelter.com") === -1 && h.indexOf("localhost") === -1 && document.referrer.toLowerCase().indexOf('staging') === -1) ? "EN81c1e8fed72e4ecebe01da3d728a0cbb" : "EN2e6dc75c567143c9aff512c4906ee798-development";
                
                    var c = document.createElement('script'); 
                        c.id = "launch-script"; 
                        c.src = "https://assets.adobedtm.com/launch-" + n + ".min.js"; 
                        c.async = 1;
                    document.head.appendChild(c)
                }
            }
        }
        //setState({bg: bgColor, homeBtn: showHomeBtn})
    }, [location, history]);  

    return (
        <Route path="/" component={Pages} />
    )
};

class App extends Component {

    state = {
        clientDefaults: null,
        clientName: null,
        page: null
    }
    
    componentDidMount() {
        if(window.self !== window.top) {
            window.addEventListener("keyup", (event) => {
                if (event.isComposing || event.keyCode === 229) {
                    return;
                }
                if(event.key === 'Escape') {
                    window.parent.postMessage('Close Gift Illustrator','*')
                }
            });
        }
        
    }

    componentDidUpdate() {

    }

    render() {
        return (
            <Router>
                <LastLocationProvider>
                    <div className="App">
                        <div className="content">
                            <div className="main">
                                <Switch>
                                    <Route path="/" component={WrappedRoutes} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </LastLocationProvider>
            </Router>
        );
    }
}

export default App;