import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.js'
import Raven from 'raven-js'
import Loadable from 'react-loadable'
const loading = () => { return (<div>Loading...</div>) }
const RAVEN_URL = 'https://ef52d8dcf14e4e1989d5ff806c469bce@sentry.io/167908'
Raven.config(RAVEN_URL).install()

const defaultConfig = { 
    mode: 'standalone', 
    targetElement: 'root',
    orgId: 'default',
    page: 'gift-annuity'
}

const setup = config =>
    ReactDOM.render(
        config.mode === 'embedded' ?
            Loadable({
                loader: () => import('./EmbeddedApp.js'),
                loading: loading,
                render(loaded, props) {
                    let Component = loaded.default
                    return <Component {...props}/>
                }
            },{...config})
        :
            <App />,
        document.getElementById(config.targetElement || 'root')
    )

setup( window.GIConfig || defaultConfig )