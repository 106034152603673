import React, {
    Component
} from 'react';
import ReactGA from 'react-ga4';

const getCookie = (name) => {
    if(typeof document.cookie.split('; ').find(row => row.startsWith(name + '=')) !== 'undefined') {
        return document.cookie.split('; ').find(row => row.startsWith(name + '=')).split('=')[1];
    }
    return null
}

const giUseAnalytics = () => {
    var orgId = window.location.pathname.split('/')[1] !== 'default' ? window.location.pathname.split('/')[1].toUpperCase() : 'A0000000';
    var useStlAnalytics = window.location.search.toLowerCase().indexOf('stl_analytics=false') > -1 || getCookie('Stl_NoAnalytics') === orgId ? false : true;
    return useStlAnalytics;
};

if(giUseAnalytics()) {
    // ReactGA.initialize('UA-99127524-1', {
    //     https: true,
    // });
    ReactGA.initialize("G-7N07RGG982"); 
}

const withTracker = (WrappedComponent, options = {}) => {
    const trackPage = page => {
        // ReactGA.set({
        //     page,
        //     ...options,
        // });
        // ReactGA.pageview(page); 
        ReactGA.send({ hitType: 'pageview', page: page})
    };

    const HOC = class extends Component {
        componentDidMount() {
            const page = this.props.location.pathname;
            if(giUseAnalytics()) trackPage(page);
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            const currentPage = this.props.location.pathname;
            const nextPage = nextProps.location.pathname;

            if (currentPage !== nextPage) {
                if(giUseAnalytics()) trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent { ...this.props
            }
            />;
        }
    };

    return HOC;
};

export default withTracker;