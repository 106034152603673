import React, {
    Component
} from 'react';
import withTracker from './withTracker.js';
import { withRouter } from 'react-router'
import utility from '../utility/parseParams.js'
import { getParam } from '../utility/helpers';
import Loader from './Loader.js'

import API from '../api.js';
const API_URL = process.env['REACT_APP_API_URL'] || 'https://calculator.giftillustrator.com/Calculator';

API.setShared(new API(API_URL));
var api = API.getShared();

const PageLoader = WrappedComponent => {

    const withDefaults = WrappedComponent => {
        const defaults = class extends Component {
            state = {
                clientDefaults: null,
            }
        
            componentDidMount() {
                this.updateClientDefaults();
            }
        
            componentDidUpdate(prevProps, prevState) {
                if (prevProps.match.params.clientName !== this.props.match.params.clientName) {
                    this.updateClientDefaults();
                }
            }
        
            async updateClientDefaults() {
                var clientName = this.props.match.params.clientName;
        
                this.setState({
                    clientDefaults: null,
                    error: null,
                });
        
                try {
                    var newDefaults = await api.clientDefault(clientName);
                    newDefaults.clientName = clientName;

                    if(this.props.location.pathname){
                        let bgColor = '#e4e0e1'
                        let validBgColorParam = getParam('bg') !== null && getParam('bg').match(/^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/g)
    
                        if(validBgColorParam) {
                            bgColor = '#' + getParam('bg')
                        } else if (typeof newDefaults !== 'undefined' && newDefaults !== null && typeof newDefaults.bgColor !== 'undefined' && newDefaults.bgColor !== null) {
                            bgColor = newDefaults.bgColor;
                            if (!bgColor.startsWith('#')) bgColor = '#' + bgColor;
                        } else if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('bgColor') !== null && sessionStorage.getItem('bgColor').length === 6) {
                            bgColor = '#' + sessionStorage.getItem('bgColor')
                        } else if (getParam('bg') !== null && !validBgColorParam) {
                            if(getParam('bg').startsWith('#')) console.log('The "bg" value in the query string should be the hex code without the leading #')
                            console.log('The "bg" value in the query string is not a valid hex code')
                            bgColor = '#e4e0e1'
                        } else {
                            bgColor = '#e4e0e1'
                        }
                        document.body.style.backgroundColor = bgColor
                    }
                } catch (e) {
                    document.body.style.backgroundColor = '#e4e0e1'
                    this.setState({error: e});
        
                    // So Sentry catches it
                    setTimeout(() => { throw e }, 0);
                }
        
                this.setState({
                    clientDefaults: newDefaults,
                });
            }

            render() {
                const params = this.props.match.params
                const addProps = {
                    ...this.props,
                    pageSlug: params.pageSlug,
                    clientName: params.clientName,
                    initialParams: utility.parseParams(this.props.location.search),
                    clientDefaults: this.state.clientDefaults
                }

                if (!this.state.clientDefaults) {
                    return (<div className="loading-page">
                        <Loader />
                    </div>);
                }

                return <WrappedComponent {...addProps} />
            }
        }
        return defaults
    }
    return withTracker(withDefaults(withRouter(WrappedComponent)))
};

export default PageLoader;