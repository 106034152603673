import Raven from 'raven-js';

export default class API {
  constructor(backend) {
    if (!backend) {
      throw new Error('parameter backend is required');
    }

    this.backend = backend;
  }

  async clientDefault(clientName) {
    var url = this.backend + '/clientDefault/get?clientName=' + clientName;
    var resp = await fetch(url);

    if (resp.status !== 200) {
      throw new Error('giftAnnuity: bad response ' + resp.status);
    }

    var data = await resp.json();

    return data;
  }

  async _sendRequest(method, params) {
    try {
      var url = this.backend + '/' + method + '?' + encodeURIParams(params);
      var resp = await fetch(url);

      if (resp.status !== 200) {
        throw new Error(method + ': bad response ' + resp.status);
      }

      var data = await resp.json();

      return data;
    } catch (e) {
      Raven.captureException(e);
      return {
        error: 'There was a problem loading your request. Please try again later.',
      };
    }
  }

  async giftAnnuity(params) {
    return this._sendRequest('giftAnnuity', params);
  }

  async annuityTrust(params) {
    if (params.trustType === 'fixed') {
      return this._sendRequest('annuityTrust', params);
    } else {
      return this._sendRequest('unitrust', params);
    }
  }

  async leadTrust(params) {
    params = Object.assign(params, {
      type: 'lead'
    })

    if (params.trustType === 'fixed') {
      return this._sendRequest('annuityTrust', params);
    } else {
      return this._sendRequest('unitrust', params);
    }
  }

  async retainedLifeEstate(params) {
    return this._sendRequest('retainedLifeEstate', params);
  }

  async outrightGift(params) {
    return this._sendRequest('outrightGift', params);
  }

  async bargainSale(params) {
    return this._sendRequest('bargainSale', params);
  }

}

var sharedAPI = new API('http://localhost:8080');

API.getShared = function() {
  return sharedAPI;
};

API.setShared = function(api) {
  sharedAPI = api;
};

function encodeURIParams(params) {
  var parts = [];
  
  for (var k in params) {
    var key = encodeURIComponent(k);
    var param = params[k] != null ? params[k] : '';
    var value = encodeURIComponent(param);
    var part = key + '=' + value;
    parts.push(part);
  }

  return parts.join('&');
}
